a {
  color: inherit;
  outline: none;
  -webkit-text-decoration: none;
  -moz-text-decoration: none;
  -ms-text-decoration: none;
  -o-text-decoration: none;
  text-decoration: none;
  &:hover, &:focus, &:active {
    color: $conity-darker;
    transition: color .3s ease-out;;
    outline: none;
    -webkit-text-decoration: none;
    -moz-text-decoration: none;
    -ms-text-decoration: none;
    -o-text-decoration: none;
    text-decoration: none;
  }

  &.link {
    position: relative;
    padding-bottom: 10px;

    &::after {
      content: "";
      width: 50%;
      height: 1px;
      background-color: $conity;
      display: block;
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 150px;
      transition: all .3s ease-out;
    }

    &:hover {
      &::after {
        width: 70%;
      }
    }
  }

  &.cancel-link {
    color: $font-capital-color;
    position: relative;
    padding-bottom: 5px;

    &:hover {
      color: $conity;

      &::after {
        width: 40%;
      }
    }

    &::after {
      content: '';
      width: 0;
      height: 1px;
      background-color: $font-capital-color;
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0;
      max-width: 280px;
      transition: 0.3s ease-out width;
    }

    &::before {
      margin: 0 1em;
      font-family: 'FontAwesome';
      font-weight: 900;
      font-size: .75rem;
      color: $font-capital-color;
      content: "\f060";
      vertical-align: middle;
    }
  }
}
