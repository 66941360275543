.card {
  margin-bottom: 1em;
  border-radius: 0px;
  border:none;
  .card-body{
    z-index:95;
    .card-filters {
      button {
        border-radius: 0.2rem;
        padding: 0.25rem 0.5rem;
        font-weight: normal;
      }
    }
  }
  #notched-delimiter{
    width:100%;
    height:auto;
  }
  .card-images{

    .image img{
      border-radius: 12%;
    }
  }

  &.edit-project {
    img {
      border-radius: 12%;
    }
  }

  .card-title {
    &::before {
      bottom: 8px;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      width: 50px;
      background: $conity;
    }
  }

  h4.card-title {
    font-size: 1.5rem;
    font-weight: 400;
    i {
      font-size: 1.9rem;
      color: $purple;
    }
  }
  h5.card-title {
    font-size: 1.1rem;
    margin-bottom: 1em;
  }
  h6 {
    font-size: 1.2rem;
    font-size: 1.1rem;
  }
  h6.card-title {
    font-size: 1.1rem;
  }

  .card-actions {
    text-align: right;
  }
  .count {
    font-weight: 500;
    margin-bottom: 0.3em;
  }

  ul {
    margin-bottom: 0;
    list-style: outside none none;
    padding: 0;

    li {
      margin-bottom: 1em;

      i {

        font-size: 16px;
        vertical-align: baseline;
        margin-right: 5px;
        color: $font-capital-color;
      }

      a {
        color: $grey-darker;

        &:hover {
          color: $link-hover-color;
          i {
            color: $link-hover-color;
          }
        }
      }

      span:not(.badge) {
        color: $font-capital-color;
        font-weight: 500;
      }
      &.active {
        border-left: 3px solid $purple;
        padding: 0.5em;
        background: $white-smoke-lighter;

        a, a:hover {
          color: $purple;
          font-weight: 500;
          i {
            color: $purple;
          }
        }
      }
    }

    &.listing {

      li {
        margin-bottom: 0.2em;

        i {
          font-size: 0.9rem;
          margin: 0;
          margin-right: 0.3em;
          color: $font-color;
          vertical-align: baseline;
          font-weight: 600;
        }

        &.highlight {
          padding: 0.2em 0;
          a {
            position: relative;
            color: $font-color;

            &::after {
              content: '';
              width: 30%;
              height: 2px;
              background-color: $conity;
              display: block;
              position: absolute;
              bottom: -5px;
              right: 0;
              max-width: 280px;
              transition: 0.3s ease-out width;
            }
            &:hover {
              &::after {
                width: 50%;
              }
            }
          }

        }
      }
    }

    &.user-listing {
      li {
        display: flex;
        flex-direction: row;
        .user-infos i{
          display:block;
          text-overflow: ellipsis;
          overflow:hidden;
          max-height:20px;
          max-width: 180px;
        }

        div {
          flex-grow: 2;
          padding-left: 1em;

          i {
            color: $grey-darker;
            font-style: italic;
          }
        }
      }
    }

    &.enhanced {
      li {
        a {

          padding-left: 0.9em;
          padding-top: 0.7em;
          padding-bottom: 0.7em;
          border-bottom: 1px solid $grey-lighter;
          border-left: 3px solid $grey-lighter;
          width: 100%;
          height: 100%;
          &:hover {
            background: $white-smoke;
          }
          &.highlight {
            border-color: $conity;
  
            span {
              font-weight: 500;
            }
          }
        }

        span:not(.badge) {
          text-transform: uppercase;
          font-size: 0.9rem;
          font-weight: 400;
        }

      }
    }
  }

  .card-projects{
    .project{
      border: 1px solid $grey-lighter;
      border-radius: 2px;

      transition: all 0.15s linear 0s;

      h6 {
        margin-bottom: 0;
      }
      p{
        padding:0;
        margin:0;
        font-size: 1rem;
      }
      &:hover {
        box-shadow: 0 8px 8px #e1e8ec;
        border-color: transparent;
      }
    }
  }

  .card-files {
    .file > a {
      display: flex;
      align-content: stretch;
      align-items: center;
      background-color:$white-smoke-darker;
      border-radius: 2px;
      margin-bottom: 1.2em;
      padding: 1em;
      width: 100%;
      transition: all 0.15s linear 0s;
      padding-left: 75px;
      background-position: 15px;
      background-repeat: no-repeat;
      background-size: 25px;
      position: relative;
      color: $grey-darker;
      &.pdf {
        background-image: url('../../../image/icons/document/pdf.png');
      }
      &.pwt {
        background-image: url('../../../image/icons/document/pwt.png');
      }
      &.excel {
        background-image: url('../../../image/icons/document/excel.png');
      }
      &.word {
        background-image: url('../../../image/icons/document/word.png');
      }
      &.archive {
        background-image: url('../../../image/icons/document/archive.png');
      }
      &.file {
        background-image: url('../../../image/icons/document/file.png');
      }
      &.image {
        background-image: url('../../../image/icons/document/image.png');
      }
      &.signature {
        background-image: url('../../../image/icons/document/signature.png');
      }
      i.round {
        color: $purple;
        border: 1px solid $purple;
        border-radius: 50%;
        margin-right: 1em;
        padding: 0.5em;
        width: 2.3em;
        height: 2.3em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .file-infos {
        flex-grow: 1;
      }
      h6 {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 320px;
        margin-bottom: 0;
      }
      span {
        color: $grey-darker;
        font-size: 0.8rem;
        display:block
      }
      .actions {
        width: 3em;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          width:25px;
          height:25px;
          border:1px solid $grey-darker;
          border-radius: 50%;
          display:flex;
          justify-content: center;
          align-items: center;

        }


      }

      &:hover {
        box-shadow: 0 8px 8px #e1e8ec;
        border-color: transparent;
        background-color: $grey-lighter;
        color:white;
      }
      &:hover i, i:hover{
        border: 1px solid white;
      }
    }
  }

  &.light {
    border: 0;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  }

  &.help {
    border: 0;
    border-left: 3px solid $purple;
    box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);

    .card-title {
      color: $purple;

      i {
        font-size: 0.8rem;
        border-radius: 50%;
        border: 1px solid #44378D;
        padding: 0.1rem;
        font-weight: 600;
      }


      &::before {
        display: none;
      }
      &::after {
        bottom: 8px;
        content: "";
        height: 2px;
        right: 0;
        position: absolute;
        width: 50px;
        background: $purple;
      }
    }
    .card-body {
      background: #fff;
      color: $purple;
      text-align: right;
    }

  }


  .card-body.help {
    /* smaller help legend */
    background: $white-smoke;
    color: $purple;
    text-align: right;
    padding: 0.5rem 1.25rem;
    font-size: 0.9rem;
  }

  &.new {
    border-left: 3px solid $conity;
  }
  .new-label{
    border-radius: 20px;
    border: 2px solid $conity;
    color: $conity;
    padding: 0px 23px;
    font-weight: bold;
  }
  .autolinked{
    color: $conity-lighter;
  }
  .link-preview{
    background-color: $white-smoke-darker;
    margin: 0px 20px 20px;
    padding-left: 10px;
    padding-right: 10px;
    a:hover {
      color: $purple;
    }
    .url{
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .description {
      color: $grey-darker;
    }
    &:hover{
      background-color: $grey-lighter;
    }
    hr{
      margin-top:0;
    }
  }
}
