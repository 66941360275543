
/*=========================
	COLORS
=========================*/
$purple-darker: #000754;
$purple: #44378D;
$purple-lighter: #6E5EB9;
$conity-darker: #E69500;
$conity: #fab507;
$conity-lighter: #FFD63F;
$green: #5CB85C;
$greeny-darker: #18AD9D;
$greeny: #18AD9D;
$greeny-lighter: #94d9c4;
$grey-darker: #797979; //Winku insteadof #959895;
$grey: #C2C7C4;
$grey-lighter: #cbcfcb;

$white: #fff;
$white-smoke-lighter: #FAFAFA;
$white-smoke: #F6F6F6;
$white-smoke-darker: #F1F1F1;

$theme-colors: map-merge(
    (
        "purple-darker": $purple-darker,
        "purple": $purple,
        "purple-lighter": $purple-lighter,
        'conity-darker': $conity-darker,
        'conity': $conity,
        'conity-lighter': $conity-lighter,
        'greeny-darker': $greeny-darker,
        'greeny': $greeny,
        'greeny-lighter': $greeny-lighter,
        'grey-darker': $grey-darker,
        'grey': $grey,
        'grey-lighter': $grey-lighter,
    ),
    $theme-colors
);

$font-family-title: 'Roboto Slab', 'sans-serif';
$font-color: #1D1D1B;
$font-capital-color: #424141;
$font-light-color: #A7A7A7;

$link-hover-color: $conity;
