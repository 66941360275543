
html {
  font-family: 'Roboto', 'sans-serif';
}

body {
  font-family: 'Roboto', 'sans-serif';
  color: $font-color;
}

p {
  line-height: 1.5rem;
  color: $font-color;

  &.legend {
    color: $grey-darker;
    font-size: 0.9rem;
  }
}


h1, h2, h3, h4, h5 {
  position: relative;
  padding-bottom: 20px;
  color: $font-capital-color;
  font-family: $font-family-title;
  font-weight: 500;

  > i {
    font-size: 1rem;
    margin-right: 0.2em;
  }
}
