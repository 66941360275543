/*======================================
	Override des variable bootstrap
=======================================*/
$font-family-base: 'Roboto', 'sans-serif';
$font-family-sans-serif: 'Roboto', 'sans-serif';

$body-color: #1D1D1B;

$primary: #6E5EB9; /* $purple-lighter */
$secondary: #F1F1F1; /* $white-smoke-darker */
$success: #18AD9D; /* $greeny */

$card-border-radius: 5px;
$card-border-color: #DEE2DF; /* $grey-lighter */

$input-border-color: transparent;
$input-border-width: 0;
$input-box-shadow:  none;

$input-focus-border-color: transparent;
$input-focus-box-shadow: none;

$alert-border-level: 6;
$alert-border-radius: 3px;
