

input[type=text], input[type=password], input[type=number], input[type=email]{
  border-radius: 0 !important;
  border-bottom: 1px solid $grey-lighter !important;

  transition: all .5s ease;

  &:focus {
    border-bottom: 2px solid $conity !important;
  }
}
textarea.form-control, textarea.form-control:focus {
  border: 1px solid $grey-lighter;
}

label {
  margin-bottom: 0;
  font-size: 0.8rem;
}
.input-group-addon {
  display: flex;
  align-items: center;
}

select {
  width: 100%;
  max-width: 100%;
  border: 1px solid $grey-lighter;
  border-radius: 3px;
  padding: 0.5em;
  background: #fff;
}
input[type=file] {
  display: none;
}

label.label-file {
  font-size: 1rem;
  cursor: pointer;
  color: $grey-darker;
  font-weight: bold;

  &:hover{
    color: $conity;
  }
}
.text-center {
  .vich-image {
    justify-content: center;

    label.label-file {
      margin-top: 0.5em;
    }
  }
}
.form-preview {
  cursor: pointer;
}
