@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;500&family=Roboto:wght@300;400;500;700&display=swap');

@import "owner/bootstrap_override";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "owner/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/utilities";
@import "~font-awesome/scss/variables";
@import "~font-awesome/scss/mixins";
@import "~font-awesome/scss/path";
@import "~font-awesome/scss/core";
@import "~font-awesome/scss/larger";
@import "~font-awesome/scss/fixed-width";
@import "~font-awesome/scss/list";
@import "~font-awesome/scss/icons";

@import "~awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css";

@import "owner/themify";
@import "owner/typography";
@import "owner/form";
@import "owner/alert";
@import "owner/card";
@import "owner/link";

//---------------
//  COMMON
//---------------
.login-wrapper {
  background: $white-smoke-darker;
  display:flex;
  flex-direction: row;
  align-items: stretch;
  align-content: stretch;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}
@include media-breakpoint-up(md) {
  .login-wrapper {
    > * {
      width: 50%;
    }
  }
}
.login-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

//---------------
//  RIGHT
//---------------
.login-right {
  display: flex;
  align-items: center;
  justify-content: center;

  .login-right-header {
    .subtitle {
      text-align: center !important;
      font-family: 'Roboto Slab';
      font-weight: 300;
      padding: 1em 0;
    }
  }
  .login-right-content {
    width: 90%;
    max-width: 550px;
  }
  .card {
    background: #fff;
    padding: 0em;
  }

  #remember-me-label {
    font-size: 1rem;
  }
  .forgot-pwd {
    margin-top: 2em;
    text-align: center;
  }
  .submit-btns {
    margin-top: 1em;
  }
}
@include media-breakpoint-up(md) {
  .login-right {
    .login-right-content {
      width: 80%;

      .card {
        padding: 1em;
      }
    }
  }
}
@include media-breakpoint-up(xl) {
  .login-right {
    .login-right-content {
      width: 70%;

      .login-header {
        .subtitle {
          padding: 3em 0;
        }
      }

      .card {
        padding: 2.5em;
      }
    }
  }
}

//---------------
//  LEFT
//---------------
.login-left {
  display: none;
  .login-left-header {
    img {
      height: 5rem;
    }
  }
  .login-left-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    margin-top: 3em;
    width: 90%;

    .image-wrapper {
      width: 75%;

      img {
        width: 100%;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .login-left {
    background: $conity-lighter;
    background: radial-gradient(circle, $conity-lighter 50%, $conity 100%);
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@include media-breakpoint-up(xl) {
  .login-left {
    .login-left-content {
      ul {
        h3 {
          font-size: 1.75rem;
        }
      }
    }
  }
}

.builder-connexion-logo {
  height: 200px;
}